// Import base variables which include the client name
@import 'variables';

// Clients
@if $client-name == 'velocity' {
  body {
    margin: 0;
  }
} @else if $client-name == 'trialkit' {
  body {
    max-width: 680px;
    margin: auto;
  }
}
