/* Your SCSS styles */
@import 'theme';

* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F2F7F7;
}

/* Your Media Queries */

@media (max-width: 320px) {
  .mainPageTitle {
    left: 45% !important;
  }
}

.custom-list-item li {
  background-color: white;
  padding: 12px;
}
.custom-list-item {
  // padding: 6px 16px;
  cursor: pointer;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 3;
}

.custom-list-item li:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.custom-list-item li strong {
  font-weight: bold;
}
